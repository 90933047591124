export class registerUserJuridico {
    patente: number;
    tipo_rif: string;
    rif: string;
    razonSoc: string;
    denomCom: string;
    municipio: number;
    parroquia: number;
    sector: number;
    direccion: string;
    telLoc: string;
    telCel: string;
    pregSeg1: string;
    respSeg1: string;
    pregSeg2: string;
    respSeg2: string;
    correo: string;
    usuario: string;
    password: string;
    accessToken: string;
    refreshToken: string;

    clear(): void {
        this.patente = undefined;
        this.tipo_rif = '';
        this.rif = '';
        this.razonSoc = '';
        this.denomCom = '';
        this.municipio = undefined;
        this.parroquia = undefined;
        this.sector = undefined;
        this.direccion = '';
        this.telLoc = '';
        this.telCel = '';
        this.pregSeg1 = '';
        this.respSeg1 = '';
        this.pregSeg2 = '';
        this.respSeg2 = '';
        this.correo = '';
        this.usuario = '';
        this.password = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
    }
}
