// Angular
import { Component, Input, OnInit } from '@angular/core';
// Lodash
import { shuffle } from 'lodash';

export interface Widget1Data {
   title: string;
   desc: string;
   value: string;
   valueClass?: string;
}

@Component({
   selector: 'kt-widget1',
   templateUrl: './widget1.component.html',
   styleUrls: ['./widget1.component.scss']
})
export class Widget1Component implements OnInit {
   // Public properties
   @Input() data: Widget1Data[];

   /**
    * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

   /**
    * On init
    */
   ngOnInit() {
      if (!this.data) {
         this.data = [
            {
               title: 'Pagado',
               desc: 'Monto Pagado',
               value: '1.000.000',
               valueClass: 'kt-font-success'
            }, {
               title: 'Por Pagar',
               desc: 'Monto Por Pagar',
               value: '200.000',
               valueClass: 'kt-font-danger'
            }, {
               title: 'Recaudado',
               desc: 'Monto Recaudado',
               value: '100.000',
               valueClass: 'kt-font-brand'
            }
         ];
      }
   }
}
