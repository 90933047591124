// Modelos
export { Empleado } from './_models/Empleado.model';
export { ListaSelect } from './_models/ListaSelect.model';
export { Propietario } from './_models/propietario.model';
export { Perfil } from './_models/perfil.model';
// Servicios
export { XsegundoService, ValorReloj } from './_services/xsegundo.service';
export { ConfiguracionService } from './_services/configuracion.service';
export { ConsultasService } from './_services/consultas.service';
export { GeneralService } from './_services/general.service';
export { DialogosService } from './_services/dialogos.service';
