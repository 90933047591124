export class Propietario {
    nacionalidad: string;
    cedula: number;
    nombre: string;
    apellido: string;
    telLoc: string;
    telCel: string;
    correo: string;

    clear() {
        this.nacionalidad = '';
        this.cedula = undefined;
        this.nombre = '';
        this.apellido = '';
        this.telLoc = '';
        this.telCel = '';
        this.correo = '';
	}
}
