// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
// import { CoreModule } from '../../core/core.module';
// import { MailModule } from './apps/mail/mail.module';
// import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';

@NgModule({
   declarations: [],
   exports: [],
   imports: [
      CommonModule,
      HttpClientModule,
      FormsModule,
      PartialsModule,
      // CoreModule,
      // MailModule,
      // ECommerceModule,
      UserManagementModule,
   ],
   providers: []
})
export class PagesModule {
}
