import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { XsegundoService, ValorReloj } from '../../../../core/suptrimza';
import { Observable } from 'rxjs';

@Component({
    selector: 'kt-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    datos$: Observable<ValorReloj>;
    hora: number;
    minutos: string;
    dia: string;
    fecha: string;
    ampm: string;
    segundos: string;

    constructor(
        private segundo: XsegundoService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.datos$ = this.segundo.getInfoReloj();
        this.datos$.subscribe(x => {
                this.hora = x.hora;
                this.minutos = x.minutos;
                this.dia = x.diadesemana;
                this.fecha = x.diaymes;
                this.ampm = x.ampm;
                this.segundos = x.segundo;
                this.cdr.markForCheck();
        });
    }
 }
