// Angular
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// Material
import { MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { map, finalize } from 'rxjs/operators';
// Services
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { ConfiguracionService } from '../../../../../core/suptrimza';
// Models
import { Role } from '../../../../../core/auth';

// Components
import { RoleEditDialogComponent } from '../role-edit/role-edit.dialog.component';

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
   selector: 'kt-roles-list',
   templateUrl: './roles-list.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesListComponent {
   // Table fieldss
   cargando: boolean;
   termino1 = '';
   registros: any[] = [];
   columnas = ['title', 'nombre', 'cedula', 'correo', 'actions'];

   constructor(
      public dialog: MatDialog,
      public snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef,
      private cs: ConfiguracionService
   ) {}

   BuscarUsuarios() {
      if (this.termino1.length > 2) {
         this.cargando = true;
         this.cs.RolesBuscar(this.termino1).pipe(
            map(d => {
               if (d.err) {
                  this.registros = [];
                  this.mensaje('No se encontraron resultados.', 'Cerrar');
               } else {
                  this.registros = d.dat;
               }
            }),
            finalize(() => {
               this.cdr.markForCheck();
               this.cargando = false;
            })
         ).subscribe();
      }
   }

   editRole(role: any) {
      const dialogRef = this.dialog.open(RoleEditDialogComponent, { data: role });
      dialogRef.afterClosed().subscribe(res => {
         if (!res) {
            return;
         }

         this.mensaje(res.msg, 'Cerrar');
         this.BuscarUsuarios();
      });
   }

   mensaje(mensaje: string, accion: string) {
      this.snackBar.open(mensaje, accion, {
         duration: 2000,
      });
   }
}
