// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Observable, of, Subscription} from 'rxjs';
// Lodash
import { each, find, some } from 'lodash';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// Services and Models
import {
   Role,
   Permission,
   selectRoleById,
   RoleUpdated,
   selectAllPermissions,
   selectAllRoles,
   selectLastCreatedRoleId,
   RoleOnServerCreated
} from '../../../../../core/auth';
import { delay, map, finalize } from 'rxjs/operators';
import { ConfiguracionService } from '../../../../../core/suptrimza';

@Component({
   selector: 'kt-role-edit-dialog',
   templateUrl: './role-edit.dialog.component.html',
   changeDetection: ChangeDetectionStrategy.Default,
})
export class RoleEditDialogComponent implements OnInit, OnDestroy {
   // Public properties
   role: Role;
   msg: string;
   role$: Observable<Role>;
   hasFormErrors = false;
   viewLoading = false;
   loadingAfterSubmit = false;
   allPermissions$: Observable<Permission[]>;
   rolePermissions: Permission[] = [];
   // Private properties
   private componentSubscriptions: Subscription;

   /**
    * Component constructor
    *
    * @param dialogRef: MatDialogRef<RoleEditDialogComponent>
    * @param data: any
    * @param store: Store<AppState>
    */
   constructor(
      public dialogRef: MatDialogRef<RoleEditDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private store: Store<AppState>,
      private cs: ConfiguracionService
   ) { }

   ngOnInit() {
      this.role = new Role();
      this.role.id = this.data.id;
      this.role.title = this.data.title;
      this.role.permissions = this.data.permissions;
      this.role.isCoreRole = this.data.isCoreRole;

      this.allPermissions$ = this.store.pipe(select(selectAllPermissions));
      this.loadPermissions();
      // });
   }

   ngOnDestroy() {
      if (this.componentSubscriptions) {
         this.componentSubscriptions.unsubscribe();
      }
   }

   loadPermissions() {
      this.allPermissions$.subscribe(_allPermissions => {
         if (!_allPermissions) {
            return;
         }
         // Itera los permisos de 1er nivel (que no tengan padre)
         const niv1Permissions = _allPermissions.filter(el => !el.parentId);
         niv1Permissions.forEach((_N1: Permission) => {
            const hasUserNiv1Permission = this.role.permissions.some(t => t === _N1.id);
            const niv1 = new Permission();
            niv1.clear();
            niv1.isSelected = hasUserNiv1Permission;
            niv1._children = [];
            niv1.id = _N1.id;
            niv1.level = _N1.level;
            niv1.parentId = _N1.parentId;
            niv1.title = _N1.title;

            // Itera los permisos de 2do nivel (que sean hijos de la iteración actual)
            const niv2Permissions = _allPermissions.filter(el => el.parentId && el.parentId === _N1.id);
            niv2Permissions.forEach(_N2 => {
               const hasUserNiv2Permission = this.role.permissions.some(t => t === _N2.id);
               const niv2 = new Permission();
               niv2.clear();
               niv2.isSelected = hasUserNiv2Permission;
               niv2._children = [];
               niv2.id = _N2.id;
               niv2.level = _N2.level;
               niv2.parentId = _N2.parentId;
               niv2.title = _N2.title;
               niv1._children.push(niv2);

               const niv3Permissions = _allPermissions.filter(el => el.parentId && el.parentId === _N2.id);
               niv3Permissions.forEach(_N3 => {
                  const hasUserNiv3Permission = this.role.permissions.some(t => t === _N3.id);
                  const niv3 = new Permission();
                  niv3.clear();
                  niv3.isSelected = hasUserNiv3Permission;
                  niv3._children = [];
                  niv3.id = _N3.id;
                  niv3.level = _N3.level;
                  niv3.parentId = _N3.parentId;
                  niv3.title = _N3.title;
                  niv2._children.push(niv3);

                  const niv4Permissions = _allPermissions.filter(el => el.parentId && el.parentId === _N3.id);
                  niv4Permissions.forEach(_N4 => {
                     const hasUserNiv4Permission = this.role.permissions.some(t => t === _N4.id);
                     const niv4 = new Permission();
                     niv4.clear();
                     niv4.isSelected = hasUserNiv4Permission;
                     niv4._children = [];
                     niv4.id = _N4.id;
                     niv4.level = _N4.level;
                     niv4.parentId = _N4.parentId;
                     niv4.title = _N4.title;
                     niv3._children.push(niv4);
                  });
               });
            });
            this.rolePermissions.push(niv1);
         });
         // console.log(this.rolePermissions);
      });
   }

   subHijos(hijos: any): boolean {
      return hijos.filter(x => x._children.length > 0).length > 0 ? true : false;
   }

   /** ACTIONS */
   /**
    * Returns permissions ids
    */
   preparePermissionIds(): number[] {
      const result = [];
      each(this.rolePermissions, (niv1: Permission) => {
         if (niv1.isSelected) {
            result.push(niv1.id);
            each(niv1._children, (niv2: Permission) => {
               if (niv2.isSelected) {
                  result.push(niv2.id);
                  each(niv2._children, (niv3: Permission) => {
                     if (niv3.isSelected) {
                        result.push(niv3.id);
                        each(niv3._children, (niv4: Permission) => {
                           if (niv4.isSelected) {
                              result.push(niv4.id);
                           }
                        });
                     }
                  });
               }
            });
         }
      });
      return result;
   }

   /**
    * Returns role for save
    */
   prepareRole(): Role {
      const _role = new Role();
      _role.id = this.role.id;
      _role.permissions = this.preparePermissionIds();
      // each(this.assignedRoles, (_role: Role) => _user.roles.push(_role.id));
      _role.title = this.role.title;
      _role.isCoreRole = this.role.isCoreRole;
      return _role;
   }

   /**
    * Save data
    */
   onSubmit() {
      this.hasFormErrors = false;
      this.loadingAfterSubmit = false;
      /** check form */
      if (!this.isTitleValid()) {
         this.hasFormErrors = true;
         return;
      }

      const editedRole = this.prepareRole();
      if (editedRole.id > 0) {
         this.updateRole(editedRole);
      } else {
         // this.createRole(editedRole);
      }
   }

   updateRole(role: Role) {
      // console.log(role);
      this.loadingAfterSubmit = true;
      this.viewLoading = true;
      this.cs.RolesActualizar(role).pipe(
         map(d => {
            if (d.err) {
               // respuesta al haber error
            } else {
               // respuesta si fue correcto
            }
            this.msg = d.msg;
         }),
         finalize(() => {
            this.viewLoading = false;
            this.dialogRef.close({
               role,
               msg: this.msg,
               isEdit: true
            });
         })
      ).subscribe();
   }

   /**
    * Close alert
    *
    * @param $event: Event
    */
   onAlertClose($event) {
      this.hasFormErrors = false;
   }

   /**
    * Check is selected changes
    *
    * @param $event: Event
    * @param permission: Permission
    */
   isSelectedChanged($event, permission: Permission) {
      if (permission._children.length === 0 && permission.isSelected) {
         const _root = find(this.rolePermissions, (item: Permission) => item.id === permission.parentId);
         if (_root && !_root.isSelected) {
            _root.isSelected = true;
         }
         return;
      }

      if (permission._children.length === 0 && !permission.isSelected) {
         const _root = find(this.rolePermissions, (item: Permission) => item.id === permission.parentId);
         if (_root && _root.isSelected) {
            if (!some(_root._children, (item: Permission) => item.isSelected === true)) {
               _root.isSelected = false;
            }
         }
         return;
      }

      if (permission._children.length > 0 && permission.isSelected) {
         each(permission._children, (item: Permission) => item.isSelected = true);
         return;
      }

      if (permission._children.length > 0 && !permission.isSelected) {
         each(permission._children, (item: Permission) => {
            item.isSelected = false;
         });
         return;
      }
   }

   isTitleValid(): boolean {
      return (this.role && this.role.title && this.role.title.length > 0);
   }
}
