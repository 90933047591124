export class PermissionsTable {
   // public static permissions: any = [
   //       {
   //          id: 1,
   //          name: 'accessToECommerceModule',
   //          level: 1,
   //          title: 'eCommerce module'
   //       },
   //       {
   //          id: 2,
   //          name: 'accessToAuthModule',
   //          level: 1,
   //          title: 'Users Management module'
   //       },
   //       {
   //          id: 3,
   //          name: 'accessToMailModule',
   //          level: 1,
   //          title: 'Mail module'
   //       },
   //       {
   //          id: 4,
   //          name: 'canReadECommerceData',
   //          level: 2,
   //          parentId: 1,
   //          title: 'Read'
   //       },
   //       {
   //          id: 5,
   //          name: 'canEditECommerceData',
   //          level: 2,
   //          parentId: 1,
   //          title: 'Edit'
   //       },
   //       {
   //          id: 6,
   //          name: 'canDeleteECommerceData',
   //          level: 2,
   //          parentId: 1,
   //          title: 'Delete'
   //       },
   //       {
   //          id: 7,
   //          name: 'canReadAuthData',
   //          level: 2,
   //          parentId: 2,
   //          title: 'Read'
   //       },
   //       {
   //          id: 8,
   //          name: 'canEditAuthData',
   //          level: 2,
   //          parentId: 2,
   //          title: 'Edit'
   //       },
   //       {
   //          id: 9,
   //          name: 'canDeleteAuthData',
   //          level: 2,
   //          parentId: 2,
   //          title: 'Delete'
   //       },
   //       {
   //          id: 10,
   //          name: 'canReadMailData',
   //          level: 2,
   //          parentId: 3,
   //          title: 'Read'
   //       },
   //       {
   //          id: 11,
   //          name: 'canEditMailData',
   //          level: 2,
   //          parentId: 3,
   //          title: 'Edit'
   //       },
   //       {
   //          id: 12,
   //          name: 'canDeleteMailData',
   //          level: 2,
   //          parentId: 3,
   //          title: 'Delete'
   //       }
   //    ];
   public static permissions: any = [
      // Configuración - Utrimza
      {
         id: 1,
         name: 'ModuloConfiguracion',
         level: 1,
         title: 'Módulo Configuración'
      },
      {
         id: 2,
         name: 'ConfiguracionUtrimza',
         level: 2,
         parentId: 1,
         title: 'Configuración - Utrimza'
      },
      {
         id: 3,
         name: 'ConfiguracionUtrimzaAgregar',
         level: 3,
         parentId: 2,
         title: 'Configuración - Utrimza -> Agregar'
      },
      {
         id: 4,
         name: 'ConfiguracionUtrimzaLeer',
         level: 3,
         parentId: 2,
         title: 'Configuración - Utrimza -> Leer'
      },
      {
         id: 5,
         name: 'ConfiguracionUtrimzaEditar',
         level: 3,
         parentId: 2,
         title: 'Configuración - Utrimza -> Editar'
      },
      {
         id: 6,
         name: 'ConfiguracionUtrimzaEliminar',
         level: 3,
         parentId: 2,
         title: 'Configuración - Utrimza -> Eliminar'
      },
      // Configuración - Petro
      {
         id: 7,
         name: 'ConfiguracionPetro',
         level: 2,
         parentId: 1,
         title: 'Configuración - Petro'
      },
      {
         id: 8,
         name: 'ConfiguracionPetroAgregar',
         level: 3,
         parentId: 7,
         title: 'Configuración - Petro -> Agregar'
      },
      {
         id: 9,
         name: 'ConfiguracionPetroLeer',
         level: 3,
         parentId: 7,
         title: 'Configuración - Petro -> Leer'
      },
      {
         id: 10,
         name: 'ConfiguracionPetroEditar',
         level: 3,
         parentId: 7,
         title: 'Configuración - Petro -> Editar'
      },
      {
         id: 11,
         name: 'ConfiguracionPetroEliminar',
         level: 3,
         parentId: 7,
         title: 'Configuración - Petro -> Eliminar'
      },
      // Configuración - Conceptos
      {
         id: 12,
         name: 'ConfiguracionConceptos',
         level: 2,
         parentId: 1,
         title: 'Configuración - Conceptos'
      },
      {
         id: 13,
         name: 'ConfiguracionConceptosAgregar',
         level: 3,
         parentId: 12,
         title: 'Configuración - Conceptos -> Agregar'
      },
      {
         id: 14,
         name: 'ConfiguracionConceptosLeer',
         level: 3,
         parentId: 12,
         title: 'Configuración - Conceptos -> Leer'
      },
      {
         id: 15,
         name: 'ConfiguracionConceptosEditar',
         level: 3,
         parentId: 12,
         title: 'Configuración - Conceptos -> Editar'
      },
      {
         id: 16,
         name: 'ConfiguracionConceptosEliminar',
         level: 3,
         parentId: 12,
         title: 'Configuración - Conceptos -> Eliminar'
      },
      // Configuración - Actividad Económica
      {
         id: 17,
         name: 'ConfiguracionActividadEconomica',
         level: 2,
         parentId: 1,
         title: 'Configuración - Actividad Económica'
      },
      // Configuración - Actividad Económica - Grupos
      {
         id: 18,
         name: 'ConfiguracionActividadEconomicaGrupos',
         level: 3,
         parentId: 17,
         title: 'Configuración - Actividad Económica - Grupos'
      },
      {
         id: 19,
         name: 'ConfiguracionActividadEconomicaGruposAgregar',
         level: 4,
         parentId: 18,
         title: 'Configuración - Actividad Económica - Grupos -> Agregar'
      },
      {
         id: 20,
         name: 'ConfiguracionActividadEconomicaGruposLeer',
         level: 4,
         parentId: 18,
         title: 'Configuración - Actividad Económica - Grupos -> Leer'
      },
      {
         id: 21,
         name: 'ConfiguracionActividadEconomicaGruposEditar',
         level: 4,
         parentId: 18,
         title: 'Configuración - Actividad Económica - Grupos -> Editar'
      },
      {
         id: 22,
         name: 'ConfiguracionActividadEconomicaGruposEliminar',
         level: 4,
         parentId: 18,
         title: 'Configuración - Actividad Económica - Grupos -> Eliminar'
      },
      // Configuración - Actividad Económica - Subgrupos
      {
         id: 23,
         name: 'ConfiguracionActividadEconomicaSubgrupos',
         level: 3,
         parentId: 17,
         title: 'Configuración - Actividad Económica - Subgrupos'
      },
      {
         id: 24,
         name: 'ConfiguracionActividadEconomicaSubgruposAgregar',
         level: 4,
         parentId: 23,
         title: 'Configuración - Actividad Económica - Subgrupos -> Agregar'
      },
      {
         id: 25,
         name: 'ConfiguracionActividadEconomicaSubgruposLeer',
         level: 4,
         parentId: 23,
         title: 'Configuración - Actividad Económica - Subgrupos -> Leer'
      },
      {
         id: 26,
         name: 'ConfiguracionActividadEconomicaSubgruposEditar',
         level: 4,
         parentId: 23,
         title: 'Configuración - Actividad Económica - Subgrupos -> Editar'
      },
      {
         id: 27,
         name: 'ConfiguracionActividadEconomicaSubgruposEliminar',
         level: 4,
         parentId: 23,
         title: 'Configuración - Actividad Económica - Subgrupos -> Eliminar'
      },
      // Estadística Mensual
      {
         id: 28,
         name: 'EstadisticaMensual',
         level: 1,
         title: 'Estadística Mensual'
      },
      // Actividad Económica
      {
         id: 29,
         name: 'ActividadEconomica',
         level: 1,
         title: 'Actividad Económica'
      },
      {
         id: 30,
         name: 'ActividadEconomicaAgregar',
         level: 2,
         parentId: 29,
         title: 'Actividad Económica -> Agregar'
      },
      {
         id: 31,
         name: 'ActividadEconomicaLeer',
         level: 2,
         parentId: 29,
         title: 'Actividad Económica -> Leer'
      },
      {
         id: 32,
         name: 'ActividadEconomicaEditar',
         level: 2,
         parentId: 29,
         title: 'Actividad Económica -> Editar'
      },
      {
         id: 33,
         name: 'ActividadEconomicaEliminar',
         level: 2,
         parentId: 29,
         title: 'Actividad Económica -> Eliminar'
      },
      // Declaración y Liquidación
      {
         id: 34,
         name: 'DeclaracionLiquidacion',
         level: 1,
         title: 'Declaración y Liquidación'
      },
      {
         id: 35,
         name: 'DeclaracionLiquidacionAgregar',
         level: 2,
         parentId: 34,
         title: 'Declaración y Liquidación -> Agregar'
      },
      {
         id: 36,
         name: 'DeclaracionLiquidacionLeer',
         level: 2,
         parentId: 34,
         title: 'Declaración y Liquidación -> Leer'
      },
      {
         id: 37,
         name: 'DeclaracionLiquidacionRechazar',
         level: 2,
         parentId: 34,
         title: 'Declaración y Liquidación -> Rechazar'
      },
      // Recaudación
      {
         id: 38,
         name: 'Recaudacion',
         level: 1,
         title: 'Recaudación'
      },
      {
         id: 39,
         name: 'RecaudacionAgregar',
         level: 2,
         parentId: 38,
         title: 'Recaudación -> Agregar'
      },
      {
         id: 40,
         name: 'RecaudacionLeer',
         level: 2,
         parentId: 38,
         title: 'Recaudación -> Leer'
      },
      {
         id: 41,
         name: 'RecaudacionEditar',
         level: 2,
         parentId: 38,
         title: 'Recaudación -> Editar'
      },
      // Conciliación
      {
         id: 42,
         name: 'Conciliacion',
         level: 1,
         title: 'Conciliación'
      },
      {
         id: 43,
         name: 'ConciliacionAgregar',
         level: 2,
         parentId: 42,
         title: 'Conciliación -> Agregar'
      },
      {
         id: 44,
         name: 'ConciliacionLeer',
         level: 2,
         parentId: 42,
         title: 'Conciliación -> Leer'
      },
      {
         id: 45,
         name: 'ConciliacionEditar',
         level: 2,
         parentId: 42,
         title: 'Conciliación -> Editar'
      },
      {
         id: 46,
         name: 'ConfiguracionUsuarios',
         level: 2,
         parentId: 1,
         title: 'Configuración - Usuarios'
      },
   ];
}
