// Angular
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { Empleado, ConfiguracionService, ConsultasService } from '../../../../../core/suptrimza';
import { ConfirmPasswordValidator } from '../../../auth/register/confirm-password.validator';

@Component({
   selector: 'kt-user-edit',
   templateUrl: './user-edit.component.html',
})

export class UserEditComponent implements OnInit {
   user: Empleado = new Empleado();
   userId$: Observable<number>;
   oldUser: Empleado;
   selectedTab = 0;
   Form: FormGroup;

   cargando: boolean;
   newMode = false;

   rifSelect = [
      {val: 'V', des: 'V'},
      {val: 'J', des: 'J'},
      {val: 'E', des: 'E'},
      {val: 'G', des: 'G'},
      {val: 'C', des: 'C'}
   ];

   nacionalidadSelect = [
      {val: 'V', des: 'V'},
      {val: 'E', des: 'E'}
   ];

   departamentoSelect = [
      {val: '1', des: 'No Aplica'}
   ];

   cargoSelect = [
      {val: '1', des: 'No Aplica'}
   ];

   estatusSelect = [
      {val: '1', des: 'No Aplica'}
   ];

   estados = [];
   municipios = [];
   parroquias = [];

   constructor(
      private activatedRoute: ActivatedRoute,
      private confS: ConfiguracionService,
      private cs: ConsultasService,
      private router: Router,
      private fb: FormBuilder,
      private snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef
   ) { }

   ngOnInit() {
      this.newMode = true;
      this.user.clear();
      this.initForm();
      this.activatedRoute.params.subscribe(params => {
         this.user.id = params.id;
         if (this.user.id) {
            this.cargando = true;
            this.confS.UsuarioDetalle(this.user.id).pipe(
               map(d => {
                  if (d.err) {
                     this.mensaje('El usuario no existe', 'Cerrar');
                  } else {
                     this.user = d.dat;
                  }
                  this.newMode = d.err;
                  this.initForm();
                  // console.log(this.user);
               }),
               finalize(() => {
                  this.cdr.markForCheck();
                  this.cargando = false;
               })
            ).subscribe();
         } else {
            // console.log('No hay ID');
         }
      });
      this.buscarEstados();
      if (!this.newMode) {
         this.buscarMunicipios();
         this.buscarParroquias();
      }
   }

   getComponentTitle() {
      return this.newMode ? 'Crear Usuario' : `Editar Usuario - ${this.user.usuario}`;
   }

   initForm() {
      this.Form = this.fb.group({
         nacionalidad: [this.user.nacionalidad, Validators.compose([
            Validators.required
         ])],
         cedula: [this.user.cedula, Validators.compose([
            Validators.required,
            Validators.maxLength(8)
         ])],
         tipoRif: [this.user.tipoRif, Validators.compose([
            Validators.required
         ])],
         rif: [this.user.rif, Validators.compose([
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(9)
         ])],
         nombre1: [this.user.nombre1, Validators.compose([
            Validators.required,
            Validators.maxLength(20)
         ])],
         nombre2: [this.user.nombre2, Validators.compose([
            Validators.maxLength(20)
         ])],
         apellido1: [this.user.apellido1, Validators.compose([
            Validators.required,
            Validators.maxLength(20)
         ])],
         apellido2: [this.user.apellido2, Validators.compose([
            Validators.maxLength(20)
         ])],
         estado: [this.user.estado, Validators.compose([
            Validators.required
         ])],
         municipio: [this.user.municipio, Validators.compose([
            Validators.required
         ])],
         parroquia: [this.user.parroquia, Validators.compose([
            Validators.required
         ])],
         direccion: [this.user.direccion, Validators.compose([
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(1000)
         ])],
         telLoc: [this.user.telLoc, Validators.compose([
            Validators.minLength(11),
            Validators.maxLength(11)
         ])],
         telCel: [this.user.telCel, Validators.compose([
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(11)
         ])],
         correo: [this.user.correo, Validators.compose([
            Validators.required,
            Validators.email,
            Validators.maxLength(100)
         ])],
         usuario: [this.user.usuario, Validators.compose([
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(15)
         ])],
         password: '',
         confirmPassword: '',
         departamento: [this.user.departamento, Validators.compose([
            Validators.required
         ])],
         cargo: [this.user.cargo, Validators.compose([
            Validators.required
         ])],
         estatus: [this.user.estatus, Validators.compose([
            Validators.required
         ])],
         fechaIng: [this.user.fechaIng, Validators.compose([
            Validators.required
         ])]
      }, {
         validator: ConfirmPasswordValidator.MatchPassword
      });
      if (!this.newMode) {
         this.Form.controls.usuario.disable();
      }
   }

   buscarEstados() {
      this.cs.listadoSelect('estados').pipe(
         map(d => this.estados = d),
         finalize(() => this.cdr.markForCheck())
      ).subscribe();
   }

   buscarMunicipios() {
      this.cs.listadoSelect('municipios', this.Form.controls.estado.value).pipe(
         map(d => this.municipios = d),
         finalize(() => this.cdr.markForCheck())
      ).subscribe();
   }

   buscarParroquias() {
      this.cs.listadoSelect('parroquias', this.Form.controls.municipio.value).pipe(
         map(d => this.parroquias = d),
         finalize(() => this.cdr.markForCheck())
      ).subscribe();
   }

   guardar() {
      const controls = this.Form.controls;
      const formInvalid = this.Form.invalid;

      if (formInvalid) {
         Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
         );
         return;
      }

      this.cargando = true;

      this.user.nacionalidad = controls.nacionalidad.value;
      this.user.cedula = controls.cedula.value;
      this.user.tipoRif = controls.tipoRif.value;
      this.user.rif = controls.rif.value;
      this.user.nombre1 = controls.nombre1.value;
      this.user.nombre2 = controls.nombre2.value;
      this.user.apellido1 = controls.apellido1.value;
      this.user.apellido2 = controls.apellido2.value;
      this.user.estado = controls.estado.value;
      this.user.municipio = controls.municipio.value;
      this.user.parroquia = controls.parroquia.value;
      this.user.direccion = controls.direccion.value;
      this.user.telLoc = controls.telLoc.value;
      this.user.telCel = controls.telCel.value;
      this.user.correo = controls.correo.value;
      this.user.usuario = controls.usuario.value;
      this.user.password = controls.password.value;
      this.user.departamento = controls.departamento.value;
      this.user.cargo = controls.cargo.value;
      this.user.estatus = controls.estatus.value;
      this.user.fechaIng = controls.fechaIng.value;

      this.confS[this.newMode ? 'UsuarioAgregar' : 'UsuarioActualizar'](this.user).pipe(
         map(res => {
            this.mensaje(res.msg, 'Cerrar');
            if (!res.err) {
               this.router.navigateByUrl('/user-management/users', { relativeTo: this.activatedRoute });
            }
         }),
         finalize(() => {
            this.cdr.markForCheck();
            this.cargando = false;
         })
      ).subscribe();
   }

   mensaje(mensaje: string, accion: string) {
      this.snackBar.open(mensaje, accion, {
         duration: 3000,
      });
   }

   isControlHasError(controlName: string, validationType: string): boolean {
      const control = this.Form.controls[controlName];
      if (!control) {
         return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
   }
}
