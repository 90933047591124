export class Perfil {
    // Jurídico
    patente: number;
    razonSoc: string;
    denomCom: string;

    // Natural
    nacionalidad: string;
    cedula: number;
    nombre1: string;
    nombre2: string;
    apellido1: string;
    apellido2: string;

    // Base
    rut: string;
    tipo_rif: string;
    rif: string;
    municipioText: string;
    parroquiaText: string;
    sectorText: string;
    municipio: number;
    parroquia: number;
    sector: number;
    direccion: string;
    telLoc: string;
    telCel: string;
    correo: string;
    usuario: string;
    type: string;

    clear(): void {
        // Jurídico
        this.patente = undefined;
        this.razonSoc = '';
        this.denomCom = '';

        // Natural
        this.nacionalidad = '';
        this.cedula = undefined;
        this.nombre1 = '';
        this.nombre2 = '';
        this.apellido1 = '';
        this.apellido2 = '';

        // Base
        this.rut = '';
        this.tipo_rif = '';
        this.rif = '';
        this.municipioText = '';
        this.parroquiaText = '';
        this.sectorText = '';
        this.municipio = undefined;
        this.parroquia = undefined;
        this.sector = undefined;
        this.direccion = '';
        this.telLoc = '';
        this.telCel = '';
        this.correo = '';
        this.usuario = '';
        this.type = '';
    }
}
