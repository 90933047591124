// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
   selector: 'kt-widget-personal',
   templateUrl: './widget-personal.component.html',
   styleUrls: ['./widget-personal.component.scss'],
})
export class WidgetPersonalComponent implements OnInit {
   // Public properties
   @Input() title: string;
   @Input() desc: string;
   @Input() data: number[];
   @ViewChild('chart', {static: true}) chart: ElementRef;

   /**
    * Component constructor
    *
    * @param layoutConfigService: LayoutConfigService
    */
   constructor(private layoutConfigService: LayoutConfigService) {
   }

   /**
    * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

   /**
    * On init
    */
   ngOnInit() {
      this.initChartJS();
   }

   /** Init chart */
   initChartJS() {
      // For more information about the chartjs, visit this link
      // https://www.chartjs.org/docs/latest/getting-started/usage.html

      const chart = new Chart(this.chart.nativeElement, {
         type: 'bar',
         data: {
            labels: ['CSC', 'VENCEMOS', 'INVECEM', 'FNC', 'ANDINO', 'CCA', 'ENTIPI'],
            datasets: [
               {
                  backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
                  data: this.data
               }
            ]
         },
         options: {
            title: {
               display: false,
            },
            tooltips: {
               intersect: false,
               mode: 'nearest',
               xPadding: 10,
               yPadding: 10,
               caretPadding: 10
            },
            legend: {
               display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            barRadius: 4,
            scales: {
               xAxes: [{
                  display: false,
                  gridLines: false,
                  stacked: true
               }],
               yAxes: [{
                  display: false,
                  stacked: true,
                  gridLines: false
               }]
            },
            layout: {
               padding: {
                  left: 0,
                  right: 0,
                  top: 4,
                  bottom: 0
               }
            }
         }
      });
   }
}
