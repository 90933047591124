// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// LOCAL
export const environment = {
   production: true,
   isMockEnabled: false, // You have to switch this, when your real back-end is done
   authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
   API_URL: 'https://api.cscvenezuela.com.ve/api/',
   API_ASSETS: 'https://api.cscvenezuela.com.ve/assets/',
   APP_BUILD_D: Date.now(),
};

// SANDBOX
// export const environment = {
//     production: true,
//     isMockEnabled: false, // You have to switch this, when your real back-end is done
//     authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
//     API_URL: 'http://a.suptrimza.gob.ve/api/',
//     API_ASSETS: 'http://a.suptrimza.gob.ve/assets/',
//     APP_BUILD_D: Date.now(),
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
