export class UserNew {
    err: boolean;
    val: boolean;
    red: string;
    id: number;
    idMd5: string;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;

    clear(): void {
        this.err = false;
        this.val = false;
        this.red = '';
        this.id = undefined;
        this.idMd5 = '';
        this.username = '';
        this.password = '';
        this.email = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
    }
}
