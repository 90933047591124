// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Guards
import { ModuleGuard } from '../../../core/auth';
import { NgxPermissionsModule } from 'ngx-permissions';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { ActionNotificationComponent } from '../../partials/content/crud';
// Components
import { UserManagementComponent } from './user-management.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RoleEditDialogComponent } from './roles/role-edit/role-edit.dialog.component';
import { UserRolesListComponent } from './users/_subs/user-roles/user-roles-list.component';
import { ChangePasswordComponent } from './users/_subs/change-password/change-password.component';
import { AddressComponent } from './users/_subs/address/address.component';
import { SocialNetworksComponent } from './users/_subs/social-networks/social-networks.component';

// Material
import {
   MatInputModule,
   MatPaginatorModule,
   MatProgressSpinnerModule,
   MatSortModule,
   MatTableModule,
   MatSelectModule,
   MatMenuModule,
   MatProgressBarModule,
   MatButtonModule,
   MatCheckboxModule,
   MatDialogModule,
   MatTabsModule,
   MatNativeDateModule,
   MatCardModule,
   MatRadioModule,
   MatIconModule,
   MatDatepickerModule,
   MatExpansionModule,
   MatAutocompleteModule,
   MAT_DIALOG_DEFAULT_OPTIONS,
   MatSnackBarModule,
   MatTooltipModule
} from '@angular/material';
import {
   usersReducer,
   UserEffects
} from '../../../core/auth';

const routes: Routes = [
   {
      path: '',
      component: UserManagementComponent,
      data: { moduleName: 'user-management' },
      canActivate: [ModuleGuard],
      children: [
         {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
         },
         {
            path: 'roles',
            component: RolesListComponent,
            data: { moduleName: 'ConfiguracionUsuariosRoles' }
         },
         {
            path: 'users',
            component: UsersListComponent,
            data: { moduleName: 'ConfiguracionUsuariosUsuarios' }
         },
         {
            path: 'users/add',
            component: UserEditComponent,
            data: { moduleName: 'ConfiguracionUsuariosUsuariosAgregar' }
         },
         {
            path: 'users/edit/:id',
            component: UserEditComponent,
            data: { moduleName: 'ConfiguracionUsuariosUsuariosEditar' }
         },
      ]
   }
];

@NgModule({
   imports: [
      CommonModule,
      HttpClientModule,
      PartialsModule,
      RouterModule.forChild(routes),
      NgxPermissionsModule.forChild(),
      StoreModule.forFeature('users', usersReducer),
        EffectsModule.forFeature([UserEffects]),
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forChild(),
      MatButtonModule,
      MatMenuModule,
      MatSelectModule,
        MatInputModule,
      MatTableModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatIconModule,
      MatNativeDateModule,
      MatProgressBarModule,
      MatDatepickerModule,
      MatCardModule,
      MatPaginatorModule,
      MatSortModule,
      MatCheckboxModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatExpansionModule,
      MatTabsModule,
      MatTooltipModule,
      MatDialogModule
   ],
   providers: [
      ModuleGuard,
      InterceptService,
      {
           provide: HTTP_INTERCEPTORS,
              useClass: InterceptService,
         multi: true
      },
      {
         provide: MAT_DIALOG_DEFAULT_OPTIONS,
         useValue: {
            hasBackdrop: true,
            panelClass: 'kt-mat-dialog-container__wrapper',
            height: 'auto',
            width: '900px'
         }
      },
      HttpUtilsService,
      TypesUtilsService,
      LayoutUtilsService
   ],
   entryComponents: [
      ActionNotificationComponent,
      RoleEditDialogComponent
   ],
   declarations: [
      UserManagementComponent,
      UsersListComponent,
      UserEditComponent,
      RolesListComponent,
      RoleEditDialogComponent,
      UserRolesListComponent,
      ChangePasswordComponent,
      AddressComponent,
      SocialNetworksComponent
   ]
})
export class UserManagementModule {
   static forRoot(): ModuleWithProviders {
      return {
         ngModule: UserManagementModule,
         providers: []
      };
   }
}
