export class UserNatural {
    id: string;
    user_type: string;
    nacionalidad: string;
    cedula: number;
    tipo_rif: string;
    rif: string;
    nombre1: string;
    nombre2: string;
    apellido1: string;
    apellido2: string;
    municipio: string;
    parroquia: string;
    sector: string;
    direccion: string;
    telLoc: string;
    telCel: string;
    pregSeg1: string;
    respSeg1: string;
    pregSeg2: string;
    respSeg2: string;
    correo: string;
    usuario: string;
    accessToken: string;
    refreshToken: string;

    clear(): void {
        this.id = '';
        this.user_type = '';
        this.nacionalidad = '';
        this.cedula = undefined;
        this.tipo_rif = '';
        this.rif = '';
        this.nombre1 = '';
        this.nombre2 = '';
        this.apellido1 = '';
        this.apellido2 = '';
        this.municipio = '';
        this.parroquia = '';
        this.sector = '';
        this.direccion = '';
        this.telLoc = '';
        this.telCel = '';
        this.pregSeg1 = '';
        this.respSeg1 = '';
        this.pregSeg2 = '';
        this.respSeg2 = '';
        this.correo = '';
        this.usuario = '';
        this.accessToken = '';
        this.refreshToken = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
    }
}
