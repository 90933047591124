export class Empleado {
    id: string;
    nacionalidad: string;
    cedula: number;
    tipoRif: string;
    rif: string;
    nombre1: string;
    nombre2: string;
    apellido1: string;
    apellido2: string;
    estado: string;
    municipio: string;
    parroquia: string;
    direccion: string;
    telLoc: string;
    telCel: string;
    correo: string;
    usuario: string;
    password: string;
    departamento: string;
    cargo: string;
    estatus: string;
    fechaIng: string;

    clear(): void {
        this.id = '';
        this.nacionalidad = '';
        this.cedula = undefined;
        this.tipoRif = '';
        this.rif = '';
        this.nombre1 = '';
        this.nombre2 = '';
        this.apellido1 = '';
        this.apellido2 = '';
        this.estado = '';
        this.municipio = '';
        this.parroquia = '';
        this.direccion = '';
        this.telLoc = '';
        this.telCel = '';
        this.correo = '';
        this.usuario = '';
        this.password = '';
        this.departamento = '';
        this.cargo = '';
        this.estatus = '';
        this.fechaIng = '';
    }
}
