import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatSnackBar } from '@angular/material';
// RXJS
import { map, finalize } from 'rxjs/operators';

import { ConfiguracionService } from '../../../../../core/suptrimza';

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
   selector: 'kt-users-list',
   templateUrl: './users-list.component.html'
})
export class UsersListComponent {
   cargando = false;

   termino1 = '';

   registros: any[] = [];
   columnas = ['username', 'nombre', 'cedula', 'email', 'actions'];

   constructor(
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private router: Router,
      private cs: ConfiguracionService,
      private cdr: ChangeDetectorRef) {}

   BuscarUsuarios() {
      if (this.termino1.length > 2) {
         this.cargando = true;
         this.cs.UsuarioBuscar(this.termino1).pipe(
            map(d => {
               if (!d.err) {
                  this.registros = d.dat;
               } else {
                  this.registros = [];
                  this.mensaje('No se encontraron resultados.', 'Cerrar');
               }
            }),
            finalize(() => {
               this.cdr.markForCheck();
               this.cargando = false;
            })
         ).subscribe();
      }
   }

   editUser(id: string) {
      this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
   }

   mensaje(mensaje: string, accion: string) {
      this.snackBar.open(mensaje, accion, {
         duration: 2000,
      });
   }
}
