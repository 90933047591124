export class RolesTable {
   public static roles: any = [
      // {
      //    id: 1,
      //    title: 'Administrator',
      //    isCoreRole: true,
      //    permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      // },
      // {
      //    id: 2,
      //    title: 'Manager',
      //    isCoreRole: false,
      //    permissions: [3, 4, 10]
      // },
      // {
      //    id: 3,
      //    title: 'Guest',
      //    isCoreRole: false,
      //    permissions: []
      // },
      {
         id: 31,  // 31 suptrimza - 7701 siat_zamora
         title: '31', // cesargil
         isCoreRole: false,
         permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46]
      },
   ];
}
