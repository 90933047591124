import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';

const userToken = localStorage.getItem(environment.authTokenKey);
const httpHeaders = {'Content-Type': 'text/plain'};

@Injectable({
   providedIn: 'root'
})

export class ConfiguracionService {

   constructor(
      private http: HttpClient
      ) {}

// ----------------------------------------
// FUNCIONES PARA USUARIOS
// ----------------------------------------

   UsuarioBuscar(term1: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/usuarios/buscar?token=' + userToken + '&termino1=' + term1);
   }

   UsuarioDetalle(id: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/usuarios/detalles?token=' + userToken + '&id=' + id, { headers: httpHeaders});
   }

   UsuarioAgregar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/usuarios/agregar?token=' + userToken, data, { headers: httpHeaders });
   }

   UsuarioActualizar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/usuarios/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA USUARIOS
// ----------------------------------------

   RolesBuscar(term1: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/roles/buscar?token=' + userToken + '&termino1=' + term1);
   }

   RolesActualizar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/roles/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA UTRIMZA
// ----------------------------------------

   UtrimzaLeer(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/utrimza?token=' + userToken, { headers: httpHeaders});
   }

   UtrimzaAgregar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/utrimza?token=' + userToken, data, { headers: httpHeaders });
   }

   UtrimzaActualizar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/utrimza/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA PETRO
// ----------------------------------------

   PetroLeer(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/petro?token=' + userToken, { headers: httpHeaders});
   }

   PetroAgregar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/petro?token=' + userToken, data, { headers: httpHeaders });
   }

   PetroActualizar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/petro/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA CONCEPTO
// ----------------------------------------

   ConceptoLeer(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/concepto?token=' + userToken, { headers: httpHeaders});
   }

   ConceptoBuscar(concepto: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/concepto/buscar?token=' + userToken + '&concepto=' + concepto, { headers: httpHeaders});
   }

   ConceptoAgregar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/concepto?token=' + userToken, data, { headers: httpHeaders });
   }

   ConceptoActualizar(data: any): Observable<any> {
      return this.http.post(environment.API_URL + 'configuracion/concepto/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA ACTIVIDADES ECONÓMICAS
// ----------------------------------------

   // GRUPOS
   ActivEconomGrupoLeer(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/actividad-economica/grupo?token=' + userToken, { headers: httpHeaders});
   }

   ActivEconomGrupoBuscar(grupo: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/actividad-economica/grupo/buscar?token=' + userToken + '&grupo=' + grupo, { headers: httpHeaders});
   }

   ActivEconomGrupoAgregar(data: any): Observable<any> {
      return this.http.post<any>(environment.API_URL + 'configuracion/actividad-economica/grupo?token=' + userToken, data, { headers: httpHeaders });
   }

   ActivEconomGrupoActualizar(data: any): Observable<any> {
      return this.http.post<any>(environment.API_URL + 'configuracion/actividad-economica/grupo/actualizar?token=' + userToken, data, { headers: httpHeaders});
   }

   // SUBGRUPOS
   ActivEconomSubgrupoLeer(grupo: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/actividad-economica/subgrupo?token=' + userToken + '&grupo=' + grupo, { headers: httpHeaders});
   }

   // Sin uso
   ActivEconomSubgrupoBuscar(subgrupo: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'configuracion/actividad-economica/subgrupo/buscar?token=' + userToken + '&subgrupo=' + subgrupo, { headers: httpHeaders});
   }

   ActivEconomSubgrupoAgregar(data: any): Observable<any> {
      return this.http.post<any>(environment.API_URL + 'configuracion/actividad-economica/subgrupo?token=' + userToken, data, { headers: httpHeaders });
   }

   ActivEconomSubgrupoActualizar(data: any): Observable<any> {
      return this.http.post<any>(environment.API_URL + 'configuracion/actividad-economica/subgrupo/actualizar?token=' + userToken, data, { headers: httpHeaders});
   }

}
