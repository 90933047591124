import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';

const userToken = localStorage.getItem(environment.authTokenKey);
const httpHeaders = {'Content-Type': 'text/plain'};

@Injectable({
   providedIn: 'root'
})

// ---------------------------------------------------------------
// GeneralService es para uso exclusivo de empleados autenticados
// ---------------------------------------------------------------

export class DialogosService {

   // userToken: Observable<User>;

   constructor(
      private http: HttpClient
      ) {}

// ----------------------------------------
// FUNCIONES PARA LOS DIALOGOS
// ----------------------------------------

   DialogRechazarDeclaracion(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/declaracion/rechazar?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogBuscarIncentivos(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/incentivo?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   DialogBuscarTasa(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/tasa?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DialogAplicarIncentivo(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/incentivo?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogBuscarDebitos(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/debito?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DialogAplicarDebito(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/debito?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogBuscarMultas(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/multa?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DialogAplicarMulta(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/multa?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   DialogCentroAsis(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/centroasis?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogDetalle(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/detalle?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   DialogDetallefar(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/detallefar?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   DialogDetallereem(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/detallereem?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   DialogDetalleamp(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/detalleamp?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   AgregarCentroasisaliComponent(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/centroasisali?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
   DialogBuscarSustitutivas(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/sustitutiva?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DialogAplicarSustitutiva(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/sustitutiva?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogBuscarReversos(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/reverso?token=' + userToken + '&usuario=' + data.id + '&recibo=' + data.recibo ;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DialogAplicarReverso(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/reverso?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }


}
